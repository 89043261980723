import React from "react";
import { Space, Table, Tag,Row,Col,Button,Modal,Input,Select,message,Pagination } from 'antd';
import {SearchOutlined, EditOutlined, StopOutlined, ExclamationCircleOutlined,PlusOutlined,PlusCircleOutlined,DeleteOutlined} from '@ant-design/icons';
import {copyText, formatDate} from '../../../utils/browserUtils';
import commonUtils from '../../../utils/commonUtils'
import PlantMinerApi from "../../../api/PlantMinerApi"
import PlantServiceApi from "../../../api/PlantServiceApi"
import RemoteMinerApi from "../../../api/remoteMinerApi"
import * as ethers from 'ethers'
import {getFilters} from "../../../utils/browserUtils";
import axios from "axios";
const { TextArea } = Input;


const s3Url = process.env.REACT_APP_S3_URL


class PVOutputMiner extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            columns:true,
            totalCount:0,
            minerSearch:{},
            createPlantInfo:{},
            showCreatePlant:false,
            confirmLoading:false,
            showCreateCSP:false,
            plants:[],
            createPlantMiner:{
                deviceId:undefined,
                provider:undefined,
                country:undefined,
                city:undefined,
                deviceModel:undefined,
                deviceName:undefined,
                ratedPower:undefined,
                clientId:undefined,
                panelLink:undefined,
                expired_time:undefined,
                ratedPower:undefined,
                round:2,
            },
            countryList : [
                {value: "Afghanistan",label: "Afghanistan"},
                {value: "Albania",label: "Albania"},
                {value: "Algeria",label: "Algeria"},
                {value: "Andorra",label: "Andorra"},
                {value: "Angola",label: "Angola"},
                {value: "Antarctica",label: "Antarctica"},
                {value: "Antigua and Barbuda",label: "Antigua and Barbuda"},
                {value: "Argentina",label: "Argentina"},
                {value: "Armenia",label: "Armenia"},
                {value: "Ashmore and Cartier Islands",label: "Ashmore and Cartier Islands"},
                {value: "Australia",label: "Australia"},
                {value: "Indian Ocean Territories",label: "Indian Ocean Territories"},
                {value: "Heard Island and McDonald Islands",label: "Heard Island and McDonald Islands"},
                {value: "Norfolk Island",label: "Norfolk Island"},
                {value: "Austria",label: "Austria"},
                {value: "Azerbaijan",label: "Azerbaijan"},
                {value: "Bahrain",label: "Bahrain"},
                {value: "Bangladesh",label: "Bangladesh"},
                {value: "Barbados",label: "Barbados"},
                {value: "Belarus",label: "Belarus"},
                {value: "Belgium",label: "Belgium"},
                {value: "Belize",label: "Belize"},
                {value: "Benin",label: "Benin"},
                {value: "Bhutan",label: "Bhutan"},
                {value: "Bolivia",label: "Bolivia"},
                {value: "Bosnia and Herzegovina",label: "Bosnia and Herzegovina"},
                {value: "Botswana",label: "Botswana"},
                {value: "Brazil",label: "Brazil"},
                {value: "Brunei",label: "Brunei"},
                {value: "Bulgaria",label: "Bulgaria"},
                {value: "Burkina Faso",label: "Burkina Faso"},
                {value: "Burundi",label: "Burundi"},
                {value: "Cambodia",label: "Cambodia"},
                {value: "Cameroon",label: "Cameroon"},
                {value: "Canada",label: "Canada"},
                {value: "Cape Verde",label: "Cape Verde"},
                {value: "Central African Republic",label: "Central African Republic"},
                {value: "Chad",label: "Chad"},
                {value: "Chile",label: "Chile"},
                {value: "China",label: "China"},
                {value: "Hong Kong S.A.R.",label: "Hong Kong S.A.R."},
                {value: "Macao S.A.R",label: "Macao S.A.R"},
                {value: "Colombia",label: "Colombia"},
                {value: "Comoros",label: "Comoros"},
                {value: "Costa Rica",label: "Costa Rica"},
                {value: "Croatia",label: "Croatia"},
                {value: "Cuba",label: "Cuba"},
                {value: "Cyprus",label: "Cyprus"},
                {value: "Czech Republic",label: "Czech Republic"},
                {value: "Democratic Republic of the Congo",label: "Democratic Republic of the Congo"},
                {value: "Denmark",label: "Denmark"},
                {value: "Greenland",label: "Greenland"},
                {value: "Faroe Islands",label: "Faroe Islands"},
                {value: "Djibouti",label: "Djibouti"},
                {value: "Dominica",label: "Dominica"},
                {value: "Dominican Republic",label: "Dominican Republic"},
                {value: "East Timor",label: "East Timor"},
                {value: "Ecuador",label: "Ecuador"},
                {value: "Egypt",label: "Egypt"},
                {value: "El Salvador",label: "El Salvador"},
                {value: "Equatorial Guinea",label: "Equatorial Guinea"},
                {value: "Eritrea",label: "Eritrea"},
                {value: "Estonia",label: "Estonia"},
                {value: "Ethiopia",label: "Ethiopia"},
                {value: "Federated States of Micronesia",label: "Federated States of Micronesia"},
                {value: "Fiji",label: "Fiji"},
                {value: "Aland",label: "Aland"},
                {value: "Finland",label: "Finland"},
                {value: "French Southern and Antarctic Lands",label: "French Southern and Antarctic Lands"},
                {value: "Saint Barthelemy",label: "Saint Barthelemy"},
                {value: "France",label: "France"},
                {value: "Wallis and Futuna",label: "Wallis and Futuna"},
                {value: "Saint Martin",label: "Saint Martin"},
                {value: "Saint Pierre and Miquelon",label: "Saint Pierre and Miquelon"},
                {value: "New Caledonia",label: "New Caledonia"},
                {value: "French Polynesia",label: "French Polynesia"},
                {value: "Gabon",label: "Gabon"},
                {value: "Gambia",label: "Gambia"},
                {value: "Georgia",label: "Georgia"},
                {value: "Germany",label: "Germany"},
                {value: "Ghana",label: "Ghana"},
                {value: "Greece",label: "Greece"},
                {value: "Grenada",label: "Grenada"},
                {value: "Guatemala",label: "Guatemala"},
                {value: "Guinea",label: "Guinea"},
                {value: "Guinea Bissau",label: "Guinea Bissau"},
                {value: "Guyana",label: "Guyana"},
                {value: "Haiti",label: "Haiti"},
                {value: "Honduras",label: "Honduras"},
                {value: "Hungary",label: "Hungary"},
                {value: "Iceland",label: "Iceland"},
                {value: "India",label: "India"},
                {value: "Indonesia",label: "Indonesia"},
                {value: "Iran",label: "Iran"},
                {value: "Iraq",label: "Iraq"},
                {value: "Ireland",label: "Ireland"},
                {value: "Israel",label: "Israel"},
                {value: "Palestine",label: "Palestine"},
                {value: "Italy",label: "Italy"},
                {value: "Ivory Coast",label: "Ivory Coast"},
                {value: "Jamaica",label: "Jamaica"},
                {value: "Japan",label: "Japan"},
                {value: "Jordan",label: "Jordan"},
                {value: "Siachen Glacier",label: "Siachen Glacier"},
                {value: "Kazakhstan",label: "Kazakhstan"},
                {value: "Kenya",label: "Kenya"},
                {value: "Kiribati",label: "Kiribati"},
                {value: "Kosovo",label: "Kosovo"},
                {value: "Kuwait",label: "Kuwait"},
                {value: "Kyrgyzstan",label: "Kyrgyzstan"},
                {value: "Laos",label: "Laos"},
                {value: "Latvia",label: "Latvia"},
                {value: "Lebanon",label: "Lebanon"},
                {value: "Lesotho",label: "Lesotho"},
                {value: "Liberia",label: "Liberia"},
                {value: "Libya",label: "Libya"},
                {value: "Liechtenstein",label: "Liechtenstein"},
                {value: "Lithuania",label: "Lithuania"},
                {value: "Luxembourg",label: "Luxembourg"},
                {value: "Macedonia",label: "Macedonia"},
                {value: "Madagascar",label: "Madagascar"},
                {value: "Malawi",label: "Malawi"},
                {value: "Malaysia",label: "Malaysia"},
                {value: "Maldives",label: "Maldives"},
                {value: "Mali",label: "Mali"},
                {value: "Malta",label: "Malta"},
                {value: "Marshall Islands",label: "Marshall Islands"},
                {value: "Mauritania",label: "Mauritania"},
                {value: "Mauritius",label: "Mauritius"},
                {value: "Mexico",label: "Mexico"},
                {value: "Moldova",label: "Moldova"},
                {value: "Monaco",label: "Monaco"},
                {value: "Mongolia",label: "Mongolia"},
                {value: "Montenegro",label: "Montenegro"},
                {value: "Morocco",label: "Morocco"},
                {value: "Mozambique",label: "Mozambique"},
                {value: "Myanmar",label: "Myanmar"},
                {value: "Namibia",label: "Namibia"},
                {value: "Nauru",label: "Nauru"},
                {value: "Nepal",label: "Nepal"},
                {value: "Aruba",label: "Aruba"},
                {value: "Curaçao",label: "Curaçao"},
                {value: "Netherlands",label: "Netherlands"},
                {value: "Sint Maarten",label: "Sint Maarten"},
                {value: "Cook Islands",label: "Cook Islands"},
                {value: "New Zealand",label: "New Zealand"},
                {value: "Niue",label: "Niue"},
                {value: "Nicaragua",label: "Nicaragua"},
                {value: "Niger",label: "Niger"},
                {value: "Nigeria",label: "Nigeria"},
                {value: "North Korea",label: "North Korea"},
                {value: "Northern Cyprus",label: "Northern Cyprus"},
                {value: "Norway",label: "Norway"},
                {value: "Oman",label: "Oman"},
                {value: "Pakistan",label: "Pakistan"},
                {value: "Palau",label: "Palau"},
                {value: "Panama",label: "Panama"},
                {value: "Papua New Guinea",label: "Papua New Guinea"},
                {value: "Paraguay",label: "Paraguay"},
                {value: "Peru",label: "Peru"},
                {value: "Philippines",label: "Philippines"},
                {value: "Poland",label: "Poland"},
                {value: "Portugal",label: "Portugal"},
                {value: "Qatar",label: "Qatar"},
                {value: "Republic of Congo",label: "Republic of Congo"},
                {value: "Republic of Serbia",label: "Republic of Serbia"},
                {value: "Romania",label: "Romania"},
                {value: "Russia",label: "Russia"},
                {value: "Rwanda",label: "Rwanda"},
                {value: "Saint Kitts and Nevis",label: "Saint Kitts and Nevis"},
                {value: "Saint Lucia",label: "Saint Lucia"},
                {value: "Saint Vincent and the Grenadines",label: "Saint Vincent and the Grenadines"},
                {value: "Samoa",label: "Samoa"},
                {value: "San Marino",label: "San Marino"},
                {value: "Sao Tome and Principe",label: "Sao Tome and Principe"},
                {value: "Saudi Arabia",label: "Saudi Arabia"},
                {value: "Senegal",label: "Senegal"},
                {value: "Seychelles",label: "Seychelles"},
                {value: "Sierra Leone",label: "Sierra Leone"},
                {value: "Singapore",label: "Singapore"},
                {value: "Slovakia",label: "Slovakia"},
                {value: "Slovenia",label: "Slovenia"},
                {value: "Solomon Islands",label: "Solomon Islands"},
                {value: "Somalia",label: "Somalia"},
                {value: "Somaliland",label: "Somaliland"},
                {value: "South Africa",label: "South Africa"},
                {value: "South Korea",label: "South Korea"},
                {value: "South Sudan",label: "South Sudan"},
                {value: "Spain",label: "Spain"},
                {value: "Sri Lanka",label: "Sri Lanka"},
                {value: "Sudan",label: "Sudan"},
                {value: "Suriname",label: "Suriname"},
                {value: "Swaziland",label: "Swaziland"},
                {value: "Sweden",label: "Sweden"},
                {value: "Switzerland",label: "Switzerland"},
                {value: "Syria",label: "Syria"},
                {value: "Tajikistan",label: "Tajikistan"},
                {value: "Thailand",label: "Thailand"},
                {value: "The Bahamas",label: "The Bahamas"},
                {value: "Togo",label: "Togo"},
                {value: "Tonga",label: "Tonga"},
                {value: "Trinidad and Tobago",label: "Trinidad and Tobago"},
                {value: "Tunisia",label: "Tunisia"},
                {value: "Turkey",label: "Turkey"},
                {value: "Taiwan",label: "Taiwan"},
                {value: "Turkmenistan",label: "Turkmenistan"},
                {value: "Uganda",label: "Uganda"},
                {value: "Ukraine",label: "Ukraine"},
                {value: "United Arab Emirates",label: "United Arab Emirates"},
                {value: "Anguilla",label: "Anguilla"},
                {value: "Bermuda",label: "Bermuda"},
                {value: "Falkland Islands",label: "Falkland Islands"},
                {value: "United Kingdom",label: "United Kingdom"},
                {value: "Guernsey",label: "Guernsey"},
                {value: "Isle of Man",label: "Isle of Man"},
                {value: "Cayman Islands",label: "Cayman Islands"},
                {value: "Jersey",label: "Jersey"},
                {value: "British Indian Ocean Territory",label: "British Indian Ocean Territory"},
                {value: "Montserrat",label: "Montserrat"},
                {value: "Pitcairn Islands",label: "Pitcairn Islands"},
                {value: "South Georgia and South Sandwich Islands",label: "South Georgia and South Sandwich Islands"},
                {value: "Saint Helena",label: "Saint Helena"},
                {value: "British Virgin Islands",label: "British Virgin Islands"},
                {value: "Turks and Caicos Islands",label: "Turks and Caicos Islands"},
                {value: "United Republic of Tanzania",label: "United Republic of Tanzania"},
                {value: "American Samoa",label: "American Samoa"},
                {value: "Guam",label: "Guam"},
                {value: "Northern Mariana Islands",label: "Northern Mariana Islands"},
                {value: "Puerto Rico",label: "Puerto Rico"},
                {value: "United States of America",label: "United States of America"},
                {value: "United States Virgin Islands",label: "United States Virgin Islands"},
                {value: "Uruguay",label: "Uruguay"},
                {value: "Uzbekistan",label: "Uzbekistan"},
                {value: "Vanuatu",label: "Vanuatu"},
                {value: "Vatican",label: "Vatican"},
                {value: "Venezuela",label: "Venezuela"},
                {value: "Vietnam",label: "Vietnam"},
                {value: "Western Sahara",label: "Western Sahara"},
                {value: "Yemen",label: "Yemen"},
                {value: "Zambia",label: "Zambia"},
                {value: "Zimbabwe",label: "Zimbabwe"},
        ]
            
        };
        this.check = this.check.bind(this)
        this.onPlantNameChange = this.onPlantNameChange.bind(this)
        this.registerPlant = this.registerPlant.bind(this)
        this.SearchPlant = this.SearchPlant.bind(this)
        this.handleCreatePlantCancel = this.handleCreatePlantCancel.bind(this)
        this.handleCreatePlantOk = this.handleCreatePlantOk.bind(this)
        this.onCreatePlantNameChange = this.onCreatePlantNameChange.bind(this)
        this.onPaginationChange = this.onPaginationChange.bind(this)
        this.onCityChange = this.onCityChange.bind(this)
        this.onPowerChange = this.onPowerChange.bind(this)
        this.onCountryChange = this.onCountryChange.bind(this)
        this.onLatitudeChange = this.onLatitudeChange.bind(this)
        this.onLongitudeChange = this.onLongitudeChange.bind(this)
        this.handleCreatePlantMiner = this.handleCreatePlantMiner.bind(this)
        this.handleUpload = this.handleUpload.bind(this)
        this.handleFileChange = this.handleFileChange.bind(this)
        this.handleClick = this.handleClick.bind(this)
        this.handleCreateCancel = this.handleCreateCancel.bind(this)
        this.onPanelLinkChange = this.onPanelLinkChange.bind(this)
        this.onMinerExpiredTimeChange = this.onMinerExpiredTimeChange.bind(this)
        this.onMinerRatedPowerChange = this.onMinerRatedPowerChange.bind(this)
        this.onMinerRoundChange = this.onMinerRoundChange.bind(this)
        this.handleCreateOk = this.handleCreateOk.bind(this)

    }
    async componentWillMount() {
        await this.check();
    }
    
    async check() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'plant_name',
                key: 'plant_name',
                width: 200,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Device Id',
                dataIndex: 'plant_id',
                key: 'plant_id',
                width: 200,
                ellipsis:true,
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
            },
            {
                title: 'Country',
                dataIndex: 'country',
                width: 100,
                key: 'country',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
            },
            {
                title: 'City',
                dataIndex: 'city',
                width: 100,
                key: 'city',
                render:(text) => <a onClick={() => {copyText(text)}}>{text}</a>
                
            },
            {
                title: 'Location',
                dataIndex: 'location',
                width: 100,
                key: 'location',
                render: (location) => {
                    let text = '';
                    let color = 'red';
                    if (location) {
                        if (typeof location === 'object') {
                            text = `Lat: ${location.latitude.toFixed(2)}, Lon: ${location.longitude.toFixed(2)}`;
                            color = 'blue';
                        } else {
                            text = JSON.stringify(location);
                        }
                    }
                    return (
                        <Tag color={color} key={location}>{text}</Tag>
                    );
                }
                
            },
            {
                title: 'Status',
                dataIndex: 'status',
                width: 100,
                key: 'status',
                render: (status) => {
                    let color = 'red'
                    let text = '已终止'
                    if(status === 1){
                        color = 'green'
                        text = '激活'
                    }
                    if(status === 2){
                        color = 'red'
                        text = '已终止'
                    }
                    return (
                        <Tag color={color} key={text}>{text}</Tag>
                    );
                }
                
            },
            {
                title: 'Create time',
                dataIndex: 'create_time',
                key: 'create_time',
                width: 200,
                render: (text) => {return <span>{text?formatDate(text):''}</span>},
            },
            {
                title: 'Action',
                key: 'action',
                fixed: 'right',
                width: 150,
                render: (_, record) => (
                    <Space size="middle">
                        
                        {
                            (record.status ===1)?<Button
                                type="primary"
                                size='small'
                                disabled={false}
                                icon={<EditOutlined />}
                                onClick={() => {this.handleCreatePlantMiner(record)}}>
                                Create Plant Miner
                            </Button>:''

                            
                        }
                    </Space>
                ),
            },
        ];
        this.setState({columns:columns})
        // 查询电站列表
        const plants = await this.queryAllPlants()
        if(!plants.error){
            const result = plants.result
            if(!result){
                this.setState({plants:[],totalCount:0})
                return;
            }
            const totalCount = result.total;
            const records = result.plants;
            this.setState({totalCount:totalCount,plants:records})
        }else{
            this.setState({plants:[],totalCount:0})
        }
    }

    async queryAllPlants(){
        let plants = await PlantServiceApi.pvoutput_queryAllPlants(this.state.minerSearch)
        return plants;
    }

    onPlantNameChange(e){
        let minerSearch = this.state.minerSearch
        minerSearch.plantName = e.target.value
        this.setState({minerSearch:minerSearch})
    }

    
    SearchPlant(){
        this.check()
    }
    formatDateStr(value){
        if(value){
            return value.replace("T"," ").replace(".000Z","")
        }else {
            return ''
        }
    }
    registerPlant(){
        this.setState({showCreatePlant:true})
    }
    handleCreatePlantCancel(){
        this.setState({showCreatePlant:false})
    }
    async handleCreatePlantOk(){
        const createPlantInfo = this.state.createPlantInfo
        const plant_name = createPlantInfo.plant_name
        if(!plant_name){
            message.error("请输入电站名称！")
            return;
        }
        if(!createPlantInfo.country){
            message.error("请选择国家！")
            return;
        }
        if(!createPlantInfo.city){
            message.error("请选择城市！")
            return;
        }
        if(!createPlantInfo.ratedPower){
            message.error("请输入电站 Rated Power！")
            return;
        }
        if(!createPlantInfo.latitude){
            message.error("请输入电站 Latitude！")
            return;
        }
        if(!createPlantInfo.longitude){
            message.error("请输入电站 Longitude！")
            return;
        }
        this.setState({confirmLoading:true});
        const params_create = {
            plant_name:createPlantInfo.plant_name,
            location:{
                latitude:Number(createPlantInfo.latitude),
                longitude:Number(createPlantInfo.longitude)
            },
            ratedPower:Number(createPlantInfo.ratedPower),
            country:createPlantInfo.country,
            city:createPlantInfo.city
        }
        const plantRecords = await PlantServiceApi.pvoutput_createPlant(params_create)
        if(!plantRecords.error){
            message.success("电站创建成功！")
            this.setState({showCreatePlant:false})
            this.SearchClient()
        }else{
            message.error(plantRecords.error.message)
        }
        this.setState({confirmLoading:false});
    }
    onCreatePlantNameChange(e){
        let createPlantInfo = this.state.createPlantInfo
        createPlantInfo.plant_name = e.target.value
        this.setState({createPlantInfo:createPlantInfo})
    }

    onLatitudeChange(e){
        const latitude = e.target.value
        let createPlantInfo = this.state.createPlantInfo
        createPlantInfo.latitude = latitude
        this.setState({createPlantInfo:createPlantInfo})
    }

    onLongitudeChange(e){
        const longitude = e.target.value
        let createPlantInfo = this.state.createPlantInfo
        createPlantInfo.longitude = longitude
        this.setState({createPlantInfo:createPlantInfo})
    }

    onPowerChange(e){
        const power = e.target.value
        let createPlantInfo = this.state.createPlantInfo
        createPlantInfo.ratedPower = power
        this.setState({createPlantInfo:createPlantInfo})
    }

    onCountryChange(value){
        let createPlantInfo = this.state.createPlantInfo
        if(!value || value.length == 0){
            error.message('country error')
            createPlantInfo.country = undefined
            this.setState({createPlantInfo:createPlantInfo})
            return;
        }
        const country = value[0]
        createPlantInfo.country = country
        this.setState({createPlantInfo:createPlantInfo})
    }

    onCityChange(e){
        const city = e.target.value
        let createPlantInfo = this.state.createPlantInfo
        createPlantInfo.city = city
        this.setState({createPlantInfo:createPlantInfo})
    }
    
    onPaginationChange(page, pageSize){
        let minerSearch = this.state.minerSearch
        minerSearch.page = page
        minerSearch.size = pageSize
        this.setState({minerSearch:minerSearch})
        this.SearchClient()
    }

    handleCreatePlantMiner(record){
        console.log(record)
        let createPlantMiner = this.state.createPlantMiner
        createPlantMiner.city = record.city;
        createPlantMiner.country = record.country;
        createPlantMiner.deviceName = record.plant_name;
        createPlantMiner.deviceId = record.plant_id;
        createPlantMiner.ratedPower = Number(record.ratedPower);
        createPlantMiner.provider = 'pvoutput'

        this.setState({createPlantMiner:createPlantMiner})
        this.setState({showCreateCSP:true})
    }

    handleCreateOk(){
        let registerMiner = this.state.createPlantMiner
        if(!registerMiner.provider){
            message.error("请输入选择provider！")
            return;
        }
        if(!registerMiner.expired_time){
            message.error("请输入Miner所属的expired_time！")
            return;
        }
        if(!registerMiner.ratedPower){
            message.error("请输入Miner所属的ratedPower！")
            return;
        }
        if(!registerMiner.round){
            message.error("请输入Miner所属的round！")
            return;
        }

        if(!registerMiner.deviceId){
            message.error("请输入Miner所属的deviceId！")
            return;
        }
          

        if(!registerMiner.panelLink){
            message.error("请输入Miner所属的光伏板图片链接！")
            return;
        }
        if(!registerMiner.country){
            message.error("请输入Miner所属的光伏板所在国家信息！")
            return;
        }
        if(!registerMiner.city){
            message.error("请输入Miner所属的光伏板所在城市信息！")
            return;
        }
        this.setState({confirmLoading:true})
        PlantMinerApi.registerMiners(registerMiner).then(resp => {
            if(!resp.error && !resp.result.error){
                message.success("Plant Miner注册成功！")
                this.setState({showCreateCSP:false})
                this.check()
            }else {
                if(resp.error){
                    message.error(resp.error.message)
                }else{
                    message.error(resp.result.error.message)
                }
                
            }
            this.setState({confirmLoading:false})
        })
    }

    handleCreateCancel(){
        this.setState({showCreateCSP:false})
    }

    onPanelLinkChange(e){
        let createPlantMiner = this.state.createPlantMiner
        createPlantMiner.panelLink = e.target.value
        this.setState({createPlantMiner:createPlantMiner})
    }

    onMinerExpiredTimeChange(e){
        let createPlantMiner = this.state.createPlantMiner
        createPlantMiner.expired_time = e.target.value
        this.setState({createPlantMiner:createPlantMiner})
    }

    onMinerRatedPowerChange(e){
        let createPlantMiner = this.state.createPlantMiner
        createPlantMiner.ratedPower = Number(e.target.value)
        this.setState({createPlantMiner:createPlantMiner})
    }
    onMinerRoundChange(e){
        let createPlantMiner = this.state.createPlantMiner
        createPlantMiner.round = Number(e.target.value)
        this.setState({createPlantMiner:createPlantMiner})
    }
    handleClick(){
        document.getElementById('fileInput').click();
      };

    handleUpload(){
        if (!this.state.selectedFile) {
          message.error('请先选择一个文件!');
          return;
        }
        let token = getFilters("token");
    
        const formData = new FormData();
        formData.append('file', this.state.selectedFile);
        formData.append('filePath','public/plant/');
        // token = token.replace(/"/g,'');
        token = token.substring(1);
        token = token.substring(0,token.length-1)
        console.log('token : ',token)
        try {
            console.log('s3Url : ',s3Url);
        axios.post(s3Url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'authorization':'Bearer '+ token
            },
          }).then(response => {
                // 假设响应包含已上传文件的 URL
                // setUploadedUrl(response.data.url);
                console.log('response : ',response);
                const code = response.data.code;
                if(code == 0){
                    this.setState({uploadedUrl:response.data.data})
                    let createPlantMiner = this.state.createPlantMiner
                    createPlantMiner.panelLink = response.data.data
                    this.setState({createPlantMiner:createPlantMiner})
                }else{
                    message.error('upload failed !!!');
                }
                
          });
    

        } catch (error) {
          console.error('上传文件时出错:', error);
          message.error('upload failed !!!');
        }
      };

      handleFileChange(event){
        const file = event.target.files[0];
        if(file){
            this.setState({selectedFile:file});
            this.setState({fileName:file.name});
        }
      };





    render() {
        return (
            <div style={{width:'100%',height:'100%',overflow:"hidden"}}>
                <Row>
                    <Col span={4}>
                        <span style={{fontWeight:'bold',fontSize:18}}>
                            Plant Manage
                        </span>
                    </Col>
                    <Col span={20}>
                        <div style={{textAlign:'right'}}>
                            <span>
                                Name:<Input onChange={this.onPlantNameChange} size='small' style={{width:180}} placeholder="plant name" allowClear />
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' onClick={this.SearchPlant} type="primary" icon={<SearchOutlined />}>Search</Button>
                            </span>
                            <span style={{marginLeft:10}}>
                            <Button style={{marginLeft:10}} size='small' danger onClick={this.registerPlant} icon={<PlusCircleOutlined />}>Create Plant</Button>
                            </span>
                        </div>
                    </Col>
                </Row>
                {this.state.totalCount>10?<div style={{textAlign:'right',paddingTop:15}}><Pagination onChange={this.onPaginationChange} size="small" total={this.state.totalCount} showSizeChanger showQuickJumper /></div>:''}
                <div style={{marginTop:10,width:'100%',height:'100%',overflow:'auto'}}>
                    <Table scroll={{x: 1620}} size='small' pagination={false} columns={this.state.columns} dataSource={this.state.plants} />
                </div>
                <Modal width='800px' title="Create Client" open={this.state.showCreatePlant} destroyOnClose={true} onOk={this.handleCreatePlantOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleCreatePlantCancel}>
                    <Row>
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Name:</span></Col>
                        }
                        {
                            // <Col span={20}><TextArea rows={2} onChange={this.onCreatePlantNameChange} /></Col>
                            <Col span={20}>
                            <Input placeholder="plant name" onChange={this.onCreatePlantNameChange}  />
                            </Col>
                        }
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Location:</span>
                        </Col>
                        <Col span={20}>
                            <Row>
                                <Col span={8} style={{paddingLeft:5}}>
                                    <Input type='number' placeholder="latitude" onChange={this.onLatitudeChange}  />
                                </Col>
                                <Col span={8} style={{paddingLeft:5}}>
                                    <Input type='number' placeholder="longitude" onChange={this.onLongitudeChange} />
                                </Col>
                            </Row>
                        </Col>
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>ratedPower:</span></Col>
                        }
                        {
                            <Col span={20}>
                            <Input placeholder="plant total power" onChange={this.onPowerChange} suffix="W"  />
                            </Col>
                        }
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>country:</span></Col>
                        }
                        {
                            <Col span={20}>
                            {/* <Input placeholder="region" onChange={this.onRegionChange} value={this.state.installPlant.region}/> */}
                            <Select mode="tags" style={{width:'100%'}}  onChange={this.onCountryChange} allowClear options={this.state.countryList} 
                                optionRender={(option) => (<Space>{option.data.value}</Space>)}  />
                        </Col>
                        }
                        {
                            <Col span={24} style={{height:10}}></Col>
                        }
                        {
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>city:</span></Col>
                        }
                        {
                           <Col span={20}>
                           <Input placeholder="city" onChange={this.onCityChange}  />
                           </Col>
                        }
                    </Row>
                </Modal>

                <Modal width='800px' title="Register Miner" open={this.state.showCreateCSP} destroyOnClose={true} onOk={this.handleCreateOk} confirmLoading={this.state.confirmLoading} onCancel={this.handleCreateCancel}>
                    <Row>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Provider:</span>
                            </Col>
                            <Col span={20}>
                            <Select style={{width:'100%'}} placeholder="Select a provider"  allowClear options={this.state.providerList} value={this.state.createPlantMiner.provider}/>
                            </Col>

                            <Col span={24} style={{height:10}}></Col>
                            <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>deviceId:</span></Col>
                            <Col span={20}><TextArea rows={2}  value={this.state.createPlantMiner.deviceId}/></Col>


                        <Col span={24} style={{height:10}}></Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Panel Link:</span></Col>
                        {/* <Col span={20}><TextArea rows={2} onChange={this.onPanelLinkChange} /></Col> */}
                        <Col span={0}><input id="fileInput" type="file" onChange={this.handleFileChange}  style={{ display: 'none' }} /> </Col>
                        { this.state.uploadedUrl? <Col span={14}><TextArea  type="text"  value={this.state.createPlantMiner.panelLink}  readOnly /> </Col> :
                            this.state.selectedFile? <Col span={14}><TextArea  type="text"  value={this.state.fileName} readOnly /> </Col> :
                            <div></div>}
                        <Col span={3}> <Button type="primary" onClick={this.handleClick}>选择文件</Button> </Col>
                        <Col span={3}> <Button type="primary" onClick={this.handleUpload}>上传文件</Button> </Col>
                        <Col span={24} style={{height:10}}></Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>Country:</span></Col>
                        <Col span={20}><TextArea rows={2}  value={this.state.createPlantMiner.country}/></Col>
                        <Col span={24} style={{height:10}}>
                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}><span style={{lineHeight:'32px'}}>City:</span></Col>
                        <Col span={20}><TextArea rows={2}  value={this.state.createPlantMiner.city}/></Col>
                        <Col span={24} style={{height:10}}>
                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>ratedPower:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="rated power of this miner" onChange={this.onMinerRatedPowerChange} suffix="W" value={this.state.createPlantMiner.ratedPower}/>
                        </Col>
                        <Col span={24} style={{height:10}}>
                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>round:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="round of this miner" onChange={this.onMinerRoundChange}  defaultValue={2}/>
                        </Col>

                        <Col span={24} style={{height:10}}>
                        </Col>
                        <Col span={4} style={{textAlign:'right',paddingRight:8}}>
                            <span style={{lineHeight:'32px'}}>Expired Time:</span>
                        </Col>
                        <Col span={20}>
                            <Input readOnly={this.state.confirmLoading} type='number' placeholder="expired time of this miner" onChange={this.onMinerExpiredTimeChange} suffix="days"/>
                        </Col>
                    </Row>
                </Modal>
            </div>
        )
    }
}

export default PVOutputMiner;
